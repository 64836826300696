@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  b {
    @apply font-calibre-semibold font-normal
  }
}
@layer components {
  .link {
    @apply text-link underline;
    color: #376649;
  }
}