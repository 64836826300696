.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.tab-link {
  color: #000;
  text-decoration: none;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

iframe {
  width: 100%;
}

@keyframes fade-in-scale-down{
  0%{
    opacity:0;
    -webkit-transform:scale(1.1);
    -ms-transform:scale(1.1);
    transform:scale(1.1)
  }
  
  100%{
    opacity:1;
    -webkit-transform:scale(1);
    -ms-transform:scale(1);
    transform:scale(1);
  }
}

#container {
  animation: fade-in-scale-down 0.2s ease-out 1;
  -webkit-animation: fade-in-scale-down 0.4s ease-in-out 1;
  -moz-animation:    fade-in-scale-down 0.4s ease-in-out 1;
  -o-animation:      fade-in-scale-down 0.4s ease-in-out 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
} */