@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff9ec; /* waaaay lighter */
  letter-spacing: -0.1px; /* Adjust letter spacing */
  line-height: 1.5; /* Adjust line spacing */
  font-family: 'EB Garamond';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: url('./assets/Inter/static/Inter-Bold.ttf')
 }

 @font-face {
  font-family: 'Inter';
  font-weight: normal;
  src: url('./assets/Inter/static/Inter-Regular.ttf')
 }

 @font-face {
  font-family: 'Calibre-Medium';
  src: url('./assets/Calibre/Calibre-Medium.otf')
 }

 @font-face {
  font-family: 'Calibre-Regular';
  src: url('./assets/Calibre/Calibre-Regular.otf')
 }

 @font-face {
  font-family: 'Calibre-Semibold';
  src: url('./assets/Calibre/Calibre-Semibold.otf')
 }

@font-face {
  font-family: 'Neue';
  font-weight: bold;
  src: url('./assets/Neue-Montreal/NeueMontreal-Bold.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: bold;
  font-style: italic;
  src: url('./assets/Neue-Montreal/NeueMontreal-BoldItalic.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: normal;
  font-style: italic;
  src: url('./assets/Neue-Montreal/NeueMontreal-Italic.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: 300;
  src: url('./assets/Neue-Montreal/NeueMontreal-Light.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: 300;
  font-style: italic;
  src: url('./assets/Neue-Montreal/NeueMontreal-LightItalic.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: 500;
  src: url('./assets/Neue-Montreal/NeueMontreal-Medium.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: 500;
  font-style: italic;
  src: url('./assets/Neue-Montreal/NeueMontreal-MediumItalic.otf');
}

@font-face {
  font-family: 'Neue';
  font-weight: normal;
  src: url('./assets/Neue-Montreal/NeueMontreal-Regular.otf');
}


@font-face {
  font-family: 'EB Garamond';
  font-weight: bold;
  src: url('./assets/EB-Garamond/EBGaramond-Bold.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: bold;
  font-style: italic;
  src: url('./assets/EB-Garamond/EBGaramond-BoldItalic.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 800;
  src: url('./assets/EB-Garamond/EBGaramond-ExtraBold.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 800;
  font-style: italic;
  src: url('./assets/EB-Garamond/EBGaramond-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: normal;
  font-style: italic;
  src: url('./assets/EB-Garamond/EBGaramond-Italic.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 500;
  src: url('./assets/EB-Garamond/EBGaramond-Medium.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 500;
  font-style: italic;
  src: url('./assets/EB-Garamond/EBGaramond-MediumItalic.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: normal;
  src: url('./assets/EB-Garamond/EBGaramond-Regular.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 600;
  src: url('./assets/EB-Garamond/EBGaramond-SemiBold.ttf');
}

@font-face {
  font-family: 'EB Garamond';
  font-weight: 600;
  font-style: italic;
  src: url('./assets/EB-Garamond/EBGaramond-SemiBoldItalic.ttf');
}
